import React, { forwardRef, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Form from "react-bootstrap/Form";

function MatTable({
  cols,
  data,
  heading,
  search,
  searchFnc,
  infoText,
  status,
}) {
  const [searchVal, setSearchVal] = useState("");
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Header className="heading">{heading}</Card.Header>
              <Card.Body>
                {status && (
                  <Form.Group className="mb-3">
                    <Form.Label>Search</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control
                          style={{ marginRight: 10 }}
                          type="text"
                          onChange={(e) => setSearchVal(e.target.value)}
                          placeholder={infoText}
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={() => searchFnc(searchVal)}
                          style={{
                            paddingBottom: 30,
                            paddingTop: 5,
                          }}
                          size="sm"
                          variant="primary"
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                )}
                <MaterialTable
                  data={data}
                  icons={tableIcons}
                  columns={cols}
                  options={{
                    showTitle: false,
                    exportButton: true,
                    paging: true,
                    pageSizeOptions: [5, 20, 40, 70],
                    search: search,
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MatTable;
